import React, { useEffect, useState } from 'react';

export const About = () => {
    return (
        <>
            <div className="w3-container w3-content w3-center w3-padding-32" style={{maxWidth: '800px'}} id="about">
                <h2 className="w3-wide">Lauren Black Does Your Hair</h2>
                <p className="w3-opacity"><i>and Makeup</i></p>
                <p className="w3-wide w3-large">Now located at<br/><a target="_blank"
                                                                      href="https://www.blackorchidsalon.com/"
                                                                      rel="noreferrer">Black Orchid Salon</a><br/>3801
                    South Congress Ave. Austin, TX 78704<br/>512-326-1010
                </p>
                <p className="w3-justify w3-center"><br/>From makeovers on dolls and family members as a child, to doing
                    friends' hair and makeup for school dances as a teen, Lauren has
                    always had a passion for beauty. Lauren not only loves doing hair and makeup, but delights in
                    helping all clients find beauty in their hair and themselves.
                    <br/>Since graduating from Avenue Five in 2012, Lauren has trained under some of the most talented
                    hair stylists in Austin, learning from the best. She is Vidal
                    Sassoon trained and particularly loves doing color. Specializing in blonding and balayage, Lauren
                    loves all color projects from small to large. Ombre, vivids, grey coverage and
                    color corrections are just some of her many specialties.<br/> Lauren also offers special occasion
                    hair and makeup, as well as many other beauty services. Onsite travel is also an option.
                    Please never hesitate to reach out to Lauren with any questions you may have about booking oreneral
                    hair inquiries. As a former teacher, she highly values continued education and is
                    consistently learning new things and challenging herself to improve her craft.<br/> For any of your
                    hair needs, Lauren is here to help you achieve your ultimate hair goals and get you feeling as
                    gorgeous as you truly are!</p>
            </div>
            <div className="w3-display-container w3-center">
                <img className="w3-image w3-circle w3-small" src="/images/LaurenBioPic.jpg" alt="Bio Pic"
                     style={{width: '100%', maxWidth: '300px'}}/>
            </div>
        </>
    );
};

const HomeButton = () => {
    return (
        <a href='/' id="nav-bar-home" className="w3-bar-item w3-button w3-padding-large">HOME</a>
    );
};

const BookNowButton = () => {
    return (
        <a id="nav-bar-book-now" target="_blank" href="https://www.blackorchidsalon.com/"
           className="w3-bar-item w3-button w3-padding-large" rel="noreferrer">BOOK NOW</a>
    );
};

const PortfolioDropDownMenu = () => {
    // if (galleries) {
    return (
        <div className="w3-hide-medium w3-dropdown-hover w3-hide-small">
            <a href="#portfolio" id="nav-bar-portfolio"
               className="w3-padding-large w3-button">PORTFOLIO <i className="fa fa-caret-down"></i></a>
            <div className="w3-dropdown-content w3-bar-block w3-card-4">
                {/*{% for gallery in galleries %}*/}
                {/*<a href="{{ gallery.get_absolute_url }}" className="w3-bar-item w3-button"*/}
                {/*   id="nav-bar-drop-down-{{ gallery.title }}">{{gallery.title}}</a>*/}
                {/*{% endfor %}*/}
            </div>
        </div>
    );
    // }
};

const DesktopNavBar = () => {
    return (
        <div id="desktop-nav-bar" className="w3-hide-small w3-hide-medium">
            <a href="#about" id="nav-bar-about"
               className="w3-bar-item w3-button w3-padding-large">ABOUT</a>
            <a href="#menu" id="nav-bar-services"
               className="w3-bar-item w3-button w3-padding-large">SERVICES</a>
            <a href="#contact" id="nav-bar-contact"
               className="w3-bar-item w3-button w3-padding-large">CONTACT</a>
            <PortfolioDropDownMenu/>
        </div>
    );
};

const HamburgerDropDownMenu = () => {
    return (
        <div className="w3-hide-medium w3-dropdown-hover w3-hide-small">
            {/*<a href="{% url 'index' %}#portfolio" id="nav-bar-portfolio" class="w3-padding-large w3-button">PORTFOLIO <i class="fa fa-caret-down"></i></a>*/}
            {/*<div class="w3-dropdown-content w3-bar-block w3-card-4">*/}
            {/*    {% for gallery in galleries %}*/}
            {/*    <a href="{{ gallery.get_absolute_url }}" class="w3-bar-item w3-button" id="nav-bar-drop-down-{{ gallery.title }}">{{ gallery.title }}</a>*/}
            {/*    {% endfor %}*/}
            {/*</div>*/}
        </div>
    );
};


const UniversalNavBar = () => {
    return (
        <div className="w3-top">
            <div id="universal-nav-bar" className="w3-bar w3-white w3-card-2">
                <HomeButton/>
                <BookNowButton/>
                <DesktopNavBar/>
                <HamburgerDropDownMenu/>
            </div>
        </div>
    );
};

export const SlideShow = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const slideShowImageCount = 7;
    const slides = Array.from({length: slideShowImageCount}, (_, i) => `/images/slideshow_${i + 1}.jpeg`);
    const currentSlide = slides[currentSlideIndex];

    const slideCount = slides.length;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlideIndex(prevIndex => (prevIndex + 1) % slideCount);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [slideCount]);

    return (
        <div id="slide-show-containter" className="w3-display-container w3-center w3-hide-large w3-hide-medium">
            <div className="mySlides">
                <img src={currentSlide} style={{width: '100%', height: '400px'}}
                     alt={`Slide ${currentSlideIndex + 1}`}/>
            </div>
        </div>
    );
};

export const Footer = () => {
    return (
        <footer className="w3-container w3-padding-16 w3-center w3-opacity w3-light-grey w3-xlarge">
            <a id="footer-fb" href="https://www.facebook.com/laurenblackdoesyourhair/" target="_blank" rel="noreferrer">
                <i className="fa fa-facebook-official w3-hover-opacity"></i></a>
            <a id="footer-insta" href="https://www.instagram.com/laurentheestylist/" target="_blank" rel="noreferrer">
                <i className="fa fa-instagram w3-hover-opacity"></i>
            </a>
            <p id="footer-dad-gum-empire" className="w3-tiny">Powered by <a href="http://www.dadgumsalsa.com/"
                                                                            target="_blank"
                                                                            className="w3-hover-text-blue"
                                                                            rel="noreferrer">Dad Gum Websites</a> and <a
                href="https://www.w3schools.com/w3css/default.asp" target="_blank" className="w3-hover-text-green"
                rel="noreferrer">w3.css</a>
            </p>
        </footer>
    );
};