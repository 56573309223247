import React from 'react';
import './App.css';
import { About, Footer, SlideShow } from './components';

function App() {
  return (
    <div className="App">
      <header>
          <SlideShow />
          <About />
      </header>
      <Footer />
    </div>
  );
}

export default App;
